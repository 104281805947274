<template>
    <div>
        <el-row>
            <el-col class="leftBox" :span="1">
                <el-checkbox class="itemCheckBox" v-model="checkState">
                    {{number}}
                </el-checkbox>
            </el-col>

            <el-col class="contents" :span="21">
                <image-type
                    v-if="isEditing"
                    ref="dialogModule"
                    :save="update"
                    :id="content.id"
                    :is-tieup="isTieup"
                    :input="content"
                    :article-format="articleFormat"
                    @close="closeMenu"
                />
                <el-row v-else class="imageItem">
                    {{ /* NOTE: InstagramAPIが使用できないため、Instagramの場合は埋め込みタグを表示（復活次第戻す）*/ }}
                    <el-row v-if="isInstagramUrl(content.quote_url)">
                        <instagram-embed :instagram-url="excludeQueryUrl(content.quote_url)"></instagram-embed>
                    </el-row>
                    <el-row v-else>
                        <img :src="content.image_src_cdn_url" :alt="content.alt_text">
                        <p class="altText" v-if="content.alt_text">alt属性: {{content.alt_text}}</p>
                        <p class="photoByText" v-if="content.photo_by_text">photo by: {{content.photo_by_text}}</p>
                        <p class="quoteHost" v-else-if="content.quote_host"><a :href="content.quote_url" target="_blank">出典: {{content.quote_host}}</a></p>
                        <p class="shutterStockLabel" v-if="isShutterStock(content.image_src_cdn_url)">ShutterStock</p>
                        <h4 class="photoTitle" v-if="content.image_title">{{content.image_title}}</h4>
                        <p class="photoComment" v-if="content.image_comment">{{content.image_comment}}</p>
                        <p class="linkUrl" v-if="content.link_url"><a :href="content.link_url" target="_blank">{{content.link_url}}（リンク先URL）</a></p>
                        <p v-if="showAddressLabel" class="addressLabel"><a style="color: #409eff;" :href="content.geo_url" target="_blank"><i class="el-icon-location"></i>{{addressLabel}}</a></p>
                    </el-row>
                </el-row>
            </el-col>

            <el-col class="rightBox" :span="2" v-if="!updateOrdersMode">
                <el-tag v-if="content.is_draft" size="mini" type="info" class="wordList" >下書き</el-tag>
                <feedback-form @save="(id, form) => $emit('updateFeedback', imageItemType, id, form)" :input="content"></feedback-form>
            </el-col>
        </el-row>

        <div v-if="!updateOrdersMode && !isEditing" class="itemFooter">
            <el-button
                type="text"
                class="btnEdit"
                @click="editItem(item)"
            >編集</el-button>
            <el-button
                type="text"
                class="btnEdit"
                @click="$emit('delete', resourceName, item.id)"
            >削除</el-button>
        </div>
    </div>
</template>

<script>
import FeedbackForm from './Components/FeedbackForm'
import ImageType from '../Form/ImageType'
import InstagramEmbed from './Components/InstagramEmbed'

export default {
    components: {
        ImageType,
        FeedbackForm,
        InstagramEmbed,
    },
    data () {
        return {
            isEditing: false,
        }
    },
    computed: {
        content() {
            return this.item.content
        },
        addressLabel() {
            return this.content.geo_title + ', ' + this.content.geo_location
        },
        showAddressLabel() {
            return Boolean(this.content.geo_title)
        },
        checkState: {
            get() {
                if (!this.item) {
                    return false
                }
                if (typeof this.item.checked === 'undefined') {
                    return false
                }
                return this.item.checked
            },
            set(check){
                this.checkItem(check,this.item)
            }
        },
        imageItemType() {
            return this.content.quote_image_url ? 'images/quote' : this.resourceName
        },
        resourceName() {
            return 'images'
        },
    },
    props: {
        articleFormat: {
            required: true,
            type: String,
        },
        item: {
            required: true,
            type: Object,
        },
        number: {
            required: true,
            type: Number,
        },
        parentCheckItem: {
            required: true,
            type: Function,
        },
        updateOrdersMode: {
            required: true,
            type: Boolean,
        },
        response: {
            required: true,
            type: Object,
        },
        parentUpdate: {
            required: true,
            type: Function,
        },
        isTieup: {
            required: true,
            type: Boolean,
        },
    },
    methods: {
        closeMenu() {
            return this.isEditing = !confirm('キャンセルしますか？')
        },
        checkItem(check,item) {
            this.parentCheckItem(check,item)
        },
        editItem(item) {
            this.isEditing = true
            setTimeout(() => {
                //ダイアログを表示した直後だとrefsで参照できないので遅延実行させる
                this.$refs.dialogModule.openDialog(this.content)
            },1)
        },
        isShutterStock(url) {
            return url.indexOf('https://cdn.macaro-ni.jp/assets/img/shutterstock') === 0
        },
        async update(type, form, id) {
            const ret = await this.parentUpdate(type, form, id)
            if (ret) {
                this.isEditing = false
            }
        },
        isInstagramUrl(url) {
            if (url === null || url === '') {
                return false
            }
            const hostname = new URL(url).hostname
            return hostname === 'instagram.com' || hostname.endsWith('.instagram.com')
        },
        excludeQueryUrl(url) {
            return url.split('?')[0]
        },
    },
    watch: {
        updateOrdersMode () {
            this.checkState = false
        }
    }
}
</script>
<style scoped>
.leftBox {
    width: 45px;
}
.contents {
    width: 732px;
}
.rightBox {
    width: 170px;
    float: right;
}
.imageItem img {
    max-width: 600px;
    margin-top:10px;
}
.altText {
    font-size:14px;
    margin-top:0;
    margin-bottom:5px;
}

.photoByText {
    margin-top:0;
    font-size:12px;
    color:#888;
}

.quoteHost,
.shutterStockLabel {
    margin-top:0;
    font-size:12px;
    color:#888;
}
.photoTitle {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
}
</style>
