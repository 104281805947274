<template>
    <div>
        <el-row type="flex" justify="end" style="margin-top:10px;font-size: 12px" >
            <el-select placeholder="編集ステータスでフィルタ" v-model="status" class="search">
                 <el-option
                     v-for="(item) in statusFilterList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
            </el-select>
            <el-select placeholder="記事ステータスでフィルタ" v-model="articleStatus" class="search">
                 <el-option
                     v-for="(item) in articleStatusFilterList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
            </el-select>
            <el-select placeholder="CCDステータスでフィルタ" v-model="ccdStatus" class="search">
                 <el-option
                     v-for="(item) in ccdStatusFilterList"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
                 </el-option>
            </el-select>
            <el-select placeholder="カテゴリでフィルタ" v-model="category" class="search">
                 <el-option
                     v-for="(item) in categoryFilterList"
                     :key="item.id"
                     :label="item.category_name"
                     :value="item.id">
                 </el-option>
            </el-select>
        </el-row>

        <el-row type="flex" justify="end" style="margin-top:10px;">
            <el-autocomplete
                class="search"
                placeholder="編集者名"
                v-model="admin"
                @select="handleSelectAdmin"
                :fetch-suggestions="querySearchAdmin"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-autocomplete
                class="search"
                placeholder="ライター名"
                v-model="writer"
                @select="handleSelectWriter"
                :fetch-suggestions="querySearchWriter"
                :trigger-on-focus="true"
            ></el-autocomplete>
            <el-input
                class="search"
                placeholder="記事ID タイトル"
                v-model="keyword"
                @keyup.enter.native="search()"
                style="width: 500px;"
            ></el-input>
            <el-button
                @click="search()"
                icon="el-icon-search"
                class="search-input"
                style="height: 40px; margin-top: 10px;"
            ></el-button>
        </el-row>

        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

        <el-row>
            <el-col >
                <check-row
                    :tableData=tableData
                    :ccdStatusLabelList=ccdStatusLabelList
                    :articleStatusLabelList=articleStatusLabelList
                    :statusList=statusFilterList
                    :ccdEditableStatus=ccdEditableStatus
                    :update="update"
                    :paymentCheck="paymentCheck"
                >
                </check-row>
            </el-col>
        </el-row>

        <pagination :handleCurrentChange="handleCurrentChange" :pagination="pagination" />

    </div>
</template>

<script>

import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import CheckRow from '../../components/Editing/CheckRow'
import moment from 'moment'


export default {
    components: {
        Pagination,
        CheckRow
    },
    beforeRouteUpdate (to, from, next) {
        next()
        this.fetch()
    },
    data() {
        return {
            categoryFilterList: [],

            admin:null,
            writer:null,

            pagination: {},
            tableData: [],

            statusFilterList: [],

            articleStatusFilterList: [],
            articleStatusLabelList: [],

            ccdStatusLabelList:[],
            ccdStatusFilterList:[],
            ccdEditableStatus:[],

            status: null,
            category: null,
            ccdStatus: null,
            articleStatus: null,
            keyword: '',
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '編集　>　編集チェック')
        this.queryInit()
        this.fetch()
    },
    methods: {
        queryInit() {
            this.status = this.$route.query.status ? Number(this.$route.query.status) : null
            this.category = this.$route.query.category ? Number(this.$route.query.category) : null
            this.ccdStatus = this.$route.query.ccdStatus ? Number(this.$route.query.ccdStatus) : null
            this.articleStatus = this.$route.query.articleStatus ? Number(this.$route.query.articleStatus) : null
            this.admin = this.$route.query.admin ? this.$route.query.admin : null
            this.writer = this.$route.query.writer ? this.$route.query.writer : null
            this.keyword = this.$route.query.keyword ? this.$route.query.keyword : ''
        },
        search() {
            const query = Object.assign({},this.$route.query,{
                status: this.status,
                articleStatus: this.articleStatus,
                ccdStatus: this.ccdStatus,
                category: this.category,
                admin: this.admin,
                writer: this.writer,
                keyword: this.keyword,
                page: 1
            })
            this.$router.push({ query })
        },
        async fetch() {
            this.$store.commit(types.PAGE_LOADING, true)

            const { data } = await axios.get('/api/editing/checks', {
                params: { ...this.$route.query }
            })

            this.pagination = Object.assign({}, data.checks)

            /*** フィルター関連　*/
            this.categoryFilterList = Object.assign([], data.categories)
            this.categoryFilterList.unshift({category_name: null, id: null})

            this.categoryFilterList.unshift({category_name: null, id: null})

            this.articleTypes = this.idKeyList(data.article_types)
            this.articleTypeFilterList = Object.assign([], data.article_types)
            this.articleTypeFilterList.unshift({type_name: null, id: null})

            this.statusFilterList = Object.assign([], data.status_list)
            this.statusFilterList.unshift({label: null, value: null})

            data.article_status_list.forEach((format) => {
                this.articleStatusLabelList[format.value] = format.label
            })
            this.articleStatusFilterList = Object.assign([], data.article_status_list)
            this.articleStatusFilterList.unshift({label: null, value: null})


            const ccdStatusList = Object.assign([], data.ccd_status_list)
            ccdStatusList.forEach((format) => {
                this.ccdStatusLabelList[format.value] = format.label
            })

            this.ccdStatusFilterList = Object.assign([], data.ccd_status_list)
            this.ccdStatusFilterList.unshift({label: null, value: null})

            this.ccdEditableStatus = Object.assign([], data.ccd_editable_status)
            this.ccdEditableStatus.unshift({label: null, value: null})

            /*** フィルター関連　*/

            this.$store.commit(types.PAGE_LOADING, false)
            const checks = Object.assign([], data.checks.data)
            this.tableData = checks.map((check) => {
                return {
                    article: check.article,
                    article_id: check.article_id,
                    comment: check.comment,
                    created_at: check.created_at,
                    deleted_at: check.deleted_at,
                    id: check.id,
                    status: check.status,
                    updated_at: check.updated_at,
                    reserved_at: check.article.reserved_at ? check.article.reserved_at : null,
                    ccd_status: check.article.ccd ? check.article.ccd.status : null,
                    editor_name: check.article.direction.editing_admin ? check.article.direction.editing_admin.editor_name : null,
                    preview_url: check.preview_url,
                    payment_check: check.payment_check,
                    article_char_count: check.article.article_char_count,
                }
            })

            this.admin = this.$route.query.admin ? String(this.$route.query.admin) : null
            this.writer = this.$route.query.writer ? String(this.$route.query.writer) : null
        },
        idKeyList(list) {
            return _.keyBy(list, (data) => data.id)
        },
        handleSelectAdmin (item) {
            this.admin = item.value
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        handleSelectWriter (item) {
            this.writer = item.value
        },
        async querySearchWriter(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
        async update (form) {
            this.$store.commit(types.LOADING, true)

            if (form.status === 300) {
                if(!confirm('公開処理を行いますが、よろしいですか？\nインセンティブの計上は完了していますか？今一度確認をお願いいたします。')){
                    this.$store.commit(types.LOADING, false)
                    return
                }

                // 公開日が指定されている場合は公開予約の処理、公開日が指定されていない場合は公開処理を行う
                if (form.reserved_at) {
                    const {error} = await axios.patch('/api/articles/' + form.article.id + '/reserve', form)

                    if (error) {
                        this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                    } else {
                        await this.fetch()
                        this.$store.commit(types.SHOW_INFO_MESSAGE, '予約処理を実行しました')
                    }
                } else {
                    const {error} = await axios.patch('/api/articles/' + form.article.id + '/publish', form)

                    if (error) {
                        this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                    } else {
                        await this.fetch()
                        this.$store.commit(types.SHOW_INFO_MESSAGE, '公開処理を実行しました')
                    }
                }
            } else {
                const {error} = await axios.patch('/api/editing/checks/' + form.id, form)

                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                } else {
                    await this.fetch()

                    this.$store.commit(types.SHOW_INFO_MESSAGE, '編集チェックを変更しました')
                }
            }



            this.$store.commit(types.LOADING, false)
        },
        async paymentCheck (form) {
            this.$store.commit(types.LOADING, true)

            if(!confirm('支払いチェックをしますか？')){
                this.$store.commit(types.LOADING, false)
                return
            }
            const {error} = await axios.post('/api/articles/' + form.article.id + '/payment_check_history')

            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                await this.fetch()
                this.$store.commit(types.SHOW_INFO_MESSAGE, '支払いチェック完了しました')
            }

            this.$store.commit(types.LOADING, false)
        },
        handleCurrentChange (page) {
            const query = Object.assign({},this.$route.query, {page})
            this.$router.push({query})
        },
        showDate(date) {
            return date ? moment(date).format('YYYY-MM-DD') : ''
        },
        showTime(date) {
            return date ? moment(date).format('HH:mm:ss') : ''
        },
    }
}
</script>
<style scoped>
.uploadButton{
    display:inline-block;
}
.wordList {
    margin:0 5px 5px 0;
}
.confirmPlot {
    white-space:pre-line;
    text-align:left;
    max-height:50px;
    padding: 0px;
}
.articleTitle{
    float:left;
    margin-top:15px;
    margin-left:7px;
    width:650px;
}

.prePlot{
    white-space:pre-line;
}
.el-button {
    margin: 5px 0 5px 10px;
}
.bottomTable {
    margin-top:5px;
}
.box-check{
    padding: 5px 20px 0 20px;
}
.search{
    margin: 10px 5px 0;
}
</style>
