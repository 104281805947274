<template>
    <el-row class="pinterestArea">
        <span v-if="pinUrl">
            <a
                data-pin-do="embedPin"
                data-pin-lang="ja"
                data-pin-width="large"
                data-pin-terse="true"
                :href="pinUrl"
            ></a>
        </span>
    </el-row>
</template>

<script>
export default {
    props: {
        pinId: {
            required: false,
            type: String,
            default: null,
        }
    },
    data() {
        return {
            pinUrl: null,
        }
    },
    watch: {
        pinId: function (newVal) {
            if (newVal) {
                this.renderWidget(newVal)
            } else {
                this.pinUrl = null
            }
        }
    },
    mounted() {
        if (this.pinId !== null) {
            this.renderWidget(this.pinId)
        }
    },
    methods: {
        renderWidget(pinId) {
            // Widget DOMを再描画する必要があるため、一度nullにリセットしている
            this.pinUrl = null
            this.$nextTick(() => {
                this.pinUrl = `https://www.pinterest.jp/pin/${pinId}`
                this.$nextTick(() => {
                    window.renderPinterestWidget(this.$el)
                })
            })
        }
    }
}
</script>
