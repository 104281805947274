import { render, staticRenderFns } from "./CheckRewrite.vue?vue&type=template&id=351ab453&scoped=true"
import script from "./CheckRewrite.vue?vue&type=script&lang=js"
export * from "./CheckRewrite.vue?vue&type=script&lang=js"
import style0 from "./CheckRewrite.vue?vue&type=style&index=0&id=351ab453&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351ab453",
  null
  
)

export default component.exports