import { render, staticRenderFns } from "./MaterialVideo.vue?vue&type=template&id=e2959f68&scoped=true"
import script from "./MaterialVideo.vue?vue&type=script&lang=js"
export * from "./MaterialVideo.vue?vue&type=script&lang=js"
import style0 from "./MaterialVideo.vue?vue&type=style&index=0&id=e2959f68&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2959f68",
  null
  
)

export default component.exports