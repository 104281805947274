<template>
    <el-dialog
        title="ライター招待"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <p v-if="user.is_invalid_writer" class="invalid-user-text">※無効ライターになっているユーザーです</p>
        <el-form ref="form" :model="createForm" label-width="120px">
            <el-form-item label="編集者">
                <el-select v-model="createForm.admin_id" placeholder="編集者">
                    <el-option
                        v-for="(name, id) in editors"
                        :key="id"
                        :label="name"
                        :value="id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="チーム名">
                <el-select v-model="createForm.writer_team_id" placeholder="チーム名">
                    <el-option
                        v-for="(name, id) in writerTeams"
                        :key="id"
                        :label="name"
                        :value="id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="ライター名">
                <el-input v-if="!user.is_invalid_writer" v-model="createForm.writer_name" placeholder="ライター名（英数字およびアンダーバーで入力）"></el-input>
                <p v-else>{{ user.writer.writer_name }}</p>
            </el-form-item>
            <el-form-item label="表示名">
                <el-input v-if="!user.is_invalid_writer" v-model="createForm.screen_name" placeholder="表示名"></el-input>
                <p v-else>{{ user.writer.screen_name }}</p>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">キャンセル</el-button>
            <el-button type="primary" @click="createWriter">招待する</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        user: Object,
        isActive: Boolean,
        writerTeams: Object,
        editors: Object,
    },
    data() {
        return {
            createForm: {
                id: '',
                admin_id: '',
                writer_team_id: '',
                writer_name: '',
                screen_name: '',
            },
        }
    },
    methods: {
        // モーダル閉じ
        close() {
            this.createForm = {
                id: '',
                admin_id: '',
                writer_team_id: '',
                writer_name: '',
                screen_name: '',
            }
            this.$emit('close')
        },
        // ライター招待
        async createWriter() {
            // 新規作成でない場合はアカウント名不要
            let params = Object.assign({}, this.createForm)
            if (this.user.is_invalid_writer) {
                delete params.writer_name
                delete params.screen_name
            }
            this.$emit('createWriter', params)
        },
    },
    watch: {
        user() {
            this.createForm.id = this.user.id
        }
    }
}
</script>
<style lang="scss" scoped>
.invalid-user-text {
    color: red;
}
</style>
