<template>
    <div>
        <el-row type="flex" justify="end">
            <el-input class="fetch-input" placeholder="材料名" v-model="searchForm.name" @keyup.enter.native="search(1)"></el-input>
            <el-input class="fetch-input" placeholder="読み方" v-model="searchForm.hiraganaName" @keyup.enter.native="search(1)"></el-input>
            <el-button @click="search(1)" icon="el-icon-search" class="search-input" style="height: 40px;"></el-button>
        </el-row>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>

        <el-row type="flex" style="margin: 30px 0;">
            <el-input v-model="createForm.name" placeholder="材料名" class="create-form-input"></el-input>
            <el-input v-model="createForm.hiragana_name" placeholder="読み方" class="create-form-input"></el-input>
            <el-input v-model="createForm.ng" placeholder="NG" class="create-form-input"></el-input>
            <el-input v-model="createForm.comment" placeholder="コメント" class="create-form-input"></el-input>
            <el-button @click="openUnitModal(createForm, null)" class="create-form-input">単位選択</el-button>
            <el-button class="add-button create-form-input" type="primary" @click="create">新規作成</el-button>
        </el-row>

        <el-table
            :data="materials"
            style="width: 100%"
        >
            <el-table-column label="材料名">
                <template v-slot="scope">
                    <el-input placeholder="材料名" v-model="scope.row.name"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="読み方">
                <template v-slot="scope">
                    <el-input placeholder="読み方" v-model="scope.row.hiragana_name"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="NG">
                <template v-slot="scope">
                    <el-input placeholder="NG" v-model="scope.row.ng"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="コメント">
                <template v-slot="scope">
                    <el-input placeholder="コメント" v-model="scope.row.comment"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="単位">
                <template v-slot="scope">
                    <el-button @click="openUnitModal(scope.row, scope.$index)">単位選択</el-button>
                </template>
            </el-table-column>
            <el-table-column label="更新">
                <template v-slot="scope">
                    <el-button
                        class="btn-warning"
                        type="warning"
                        @click="update(scope.row)"
                    >更新
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="削除">
                <template v-slot="scope">
                    <el-button
                        class="btn-danger"
                        type="danger"
                        @click="deleteMaterial(scope.row)"
                    >削除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <pagination :handleCurrentChange="search" :pagination="pagination"/>

        <unit-modal
            :is-active="isModalActive"
            :material-units="materialUnits"
            :material="selectMaterial"
            @close="closeUnitModal"
        ></unit-modal>
    </div>
</template>

<script>
import * as types from '../../store/mutation-types'
import Pagination from '../../components/Pagination'
import UnitModal from './Material/UnitModal'

export default {
    components: {
        Pagination,
        UnitModal,
    },
    data() {
        return {
            searchForm: {
                name: '',
                hiraganaName: '',
            },
            createForm: {
                name: '',
                hiragana_name: '',
                ng: '',
                comment: '',
                suggest_unit_ids: '',
                suggest_unit: [],
            },
            pagination: {},
            materials: [],
            materialUnits: [],
            isModalActive: false,
            selectMaterial: {},
            selectMaterialIdx: 0,
        }
    },
    created() {
        this.$store.commit(types.PAGE_TITLE, '記事作成データ管理　>　材料')
        this.searchForm.name = this.$route.query.name ? this.$route.query.name : ''
        this.searchForm.hiraganaName = this.$route.query.hiragana_name ? this.$route.query.hiragana_name : ''
        this.fetch()
    },
    methods: {
        // 検索
        async fetch() {
            this.$store.commit(types.LOADING, true)

            const page = this.$route.query.page ? this.$route.query.page : 1
            const name = this.$route.query.name ? this.$route.query.name : ''
            const hiraganaName = this.$route.query.hiragana_name ? this.$route.query.hiragana_name : ''

            const {data, error} = await axios.get('/api/suggests/materials', {
                params: {
                    page: page,
                    name: name,
                    hiragana_name: hiraganaName,
                }
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.pagination = Object.assign({}, data.suggest_materials)
            this.materials = Object.assign([], data.suggest_materials.data)
            this.materialUnits = Object.assign([], data.suggest_units)

            this.$store.commit(types.LOADING, false)
        },
        search(page) {
            this.$router.push({
                query: {
                    page: page,
                    name: this.searchForm.name,
                    hiragana_name: this.searchForm.hiraganaName
                }
            })
            this.fetch()
        },
        openUnitModal(material, idx) {
            this.selectMaterial = material
            this.selectMaterialIdx = idx
            this.isModalActive = true
        },
        closeUnitModal(suggestUnits) {
            if (this.selectMaterialIdx === null) {
                this.$set(this.createForm, 'suggest_unit', suggestUnits)
            } else {
                this.$set(this.materials[this.selectMaterialIdx], 'suggest_unit', suggestUnits)
            }
            this.isModalActive = false
        },
        async update(material) {
            this.$store.commit(types.LOADING, true)

            const suggestUnitIds = this.getSuggestUnitIds(material.suggest_unit)

            const { data, error } = await axios.put('/api/suggests/materials/'+material.id, {
                id: material.id,
                name: material.name,
                hiragana_name: material.hiragana_name,
                ng: material.ng,
                comment: material.comment,
                suggest_unit_ids: suggestUnitIds,
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        },
        async create() {
            this.$store.commit(types.LOADING, true)

            this.createForm.suggest_unit_ids = this.getSuggestUnitIds(this.createForm.suggest_unit)

            const { data, error } = await axios.post('/api/suggests/materials', this.createForm)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.createForm = {
                name: '',
                hiragana_name: '',
                ng: '',
                comment: '',
                suggest_unit_ids: '',
            }

            this.fetch()
        },
        async deleteMaterial(material) {
            if (! confirm('本当に削除しますか？')) {
                return
            }

            this.$store.commit(types.LOADING, true)

            const { data, error } = await axios.delete('/api/suggests/materials/'+material.id)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
                this.$store.commit(types.LOADING, false)
                return
            }

            this.fetch()
        },
        getSuggestUnitIds(suggestUnits) {
            let suggestUnitIds = []
            suggestUnits.forEach(suggestUnit => {
                suggestUnitIds.push(suggestUnit.id)
            })

            return suggestUnitIds
        }
    }
}
</script>

<style lang="scss" scoped>
    .fetch-input {
        width: 500px;
        margin-right: 20px;
    }
    .create-form-input {
        margin-right: 10px;
    }
</style>
