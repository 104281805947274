<template>
    <div class="recipeSummaryItem">
        <div class="recipeSummaryItem__caution">※単一レシピ記事で使用するアイテムを一括で作成できます</div>
        <el-form class="itemForm" @close="$emit('close')" @submit.native.prevent label-width="140px" >
            <div class="recipeSummaryItem__formSectionTitle">基本情報</div>
            <el-form-item label="大見出し" required>
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.basic_heading"
                />
                <text-length-count :targetText="form.basic_heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item label="画像" required>
                <div class="recipeSummaryItem__imageWrapper">
                    <el-row class="recipeSummaryItem__image">
                        <el-upload
                            :headers="imageRequestHeader"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleBasicImageAdd"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="form.basic_image_cdn_url" :src="form.basic_image_cdn_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-row>
                    <el-row>
                        <el-col :span="24" class="recipeSummaryItem__imageContents">
                            <el-input
                                type="text"
                                size="mini"
                                v-model="form.basic_alt_text"
                                placeholder="alt属性"
                            />
                        </el-col>
                        <el-col :span="24">
                            <el-input
                                type="text"
                                size="mini"
                                v-model="form.basic_photo_by"
                                placeholder="photoBy"
                            />
                        </el-col>
                    </el-row>
                </div>
            </el-form-item>
            <el-form-item label="調理時間">
                <el-row class="recipeSummaryItem__innerFormContent">
                    <el-col :span="12">
                        <span class="recipeSummaryItem__inlineLabel">調理時間</span>
                        <el-input-number
                            size="mini"
                            v-model="form.cooking_time"
                            :min="0"
                        ></el-input-number> 分
                    </el-col>
                </el-row>
                <el-row class="recipeSummaryItem__innerFormContent">
                    <el-col :span="24">
                        <div class="recipeSummaryItem__innerFormLabel">調理時間に関するコメント</div>
                        <el-input
                            type="text"
                            size="mini"
                            v-model="form.cooking_time_comment"
                        />
                        <text-length-count :targetText="form.cooking_time_comment" :maxTextLength="255" />
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="栄養情報">
                <div class="recipeSummaryItem__caution">「補足テキスト」を入力することで、作成されます</div>
                <el-row class="recipeSummaryItem__innerFormContent">
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">エネルギー</div>
                        <el-input-number
                            size="mini"
                            v-model="form.calorie"
                            :min="0"
                        ></el-input-number> Kcal
                    </el-col>
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">タンパク質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.protein"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">脂質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.lipid"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                </el-row>
                <el-row class="recipeSummaryItem__innerFormContent">
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">炭水化物</div>
                        <el-input-number
                            size="mini"
                            v-model="form.carbohydrate"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">塩分量</div>
                        <el-input-number
                            size="mini"
                            v-model="form.salt"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                    <el-col :span="8">
                        <div class="recipeSummaryItem__innerFormLabel">糖質</div>
                        <el-input-number
                            size="mini"
                            v-model="form.saccharinity"
                            :min="0"
                        ></el-input-number> g
                    </el-col>
                </el-row>
                <el-row class="recipeSummaryItem__innerFormContent">
                    <el-col :span="24">
                        <div class="recipeSummaryItem__innerFormLabel">補足テキスト</div>
                        <el-input
                            type="text"
                            v-model="form.nutrient_comment"
                            size="mini"
                        ></el-input>
                        <text-length-count :targetText="form.nutrient_comment" :maxTextLength="255" />
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item label="テキスト" required>
                <el-input
                    type="textarea"
                    size="mini"
                    v-model="form.basic_description"
                />
            </el-form-item>

            <div class="recipeSummaryItem__formSectionTitle">材料</div>
            <el-form-item label="大見出し" required>
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.material_heading"
                />
                <text-length-count :targetText="form.material_heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item label="画像" required>
                <div class="recipeSummaryItem__imageWrapper">
                    <el-row class="recipeSummaryItem__image">
                        <el-upload
                            :headers="imageRequestHeader"
                            class="avatar-uploader"
                            :auto-upload="false"
                            action=""
                            :on-change="handleMaterialImageAdd"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="form.material_image_cdn_url" :src="form.material_image_cdn_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-row>
                    <el-row>
                        <el-col :span="24" class="recipeSummaryItem__imageContents">
                            <el-input
                                type="text"
                                size="mini"
                                v-model="form.material_alt_text"
                                placeholder="alt属性"
                            />
                        </el-col>
                        <el-col :span="24">
                            <el-input
                                type="text"
                                size="mini"
                                v-model="form.material_photo_by"
                                placeholder="photoBy"
                            />
                        </el-col>
                    </el-row>
                </div>
            </el-form-item>
            <el-form-item label="提供人数">
                <el-row>
                    <div class="recipeSummaryItem__caution">保存すると、「材料：大見出し」に人数が反映されます</div>
                    <div class="recipeSummaryItem__caution">自由入力が優先されます</div>
                </el-row>
                <el-row>
                    <el-input-number
                        size="mini"
                        v-model="form.servings_min"
                        :min="0"
                    ></el-input-number> 人分〜
                    <el-input-number
                        size="mini"
                        v-model="form.servings_max"
                        :min="0"
                    ></el-input-number> 人分
                </el-row>
                <el-row>
                    <div class="recipeSummaryItem__innerFormLabel">自由入力</div>
                    <el-input
                        type="text"
                        size="mini"
                        v-model="form.servings_free_text"
                        placeholder="自由入力"
                    />
                    <text-length-count :targetText="form.servings_free_text" :maxTextLength="255" />
                </el-row>
            </el-form-item>
            <el-form-item label="材料" required>
                <el-row v-for="(element, idx) in form.material_elements" :key="idx">
                    <div class="materialList">
                        <div class="materialListMainItem">
                            <span class="required"></span>
                            <el-autocomplete
                                v-model="element.material.material_name"
                                class="materialListMainItem__formElement"
                                type="text"
                                size="mini"
                                :fetch-suggestions="suggestMaterial"
                                @focus="focusMaterialSuggestList(idx)"
                                @select="showMaterialSuggestUnit"
                                placeholder="材料・調味料"
                            ></el-autocomplete>
                            <el-input
                                v-model="element.quantity_text"
                                class="materialListMainItem__quantityElement"
                                type="text"
                                size="mini"
                                placeholder="分量"
                            >
                                <el-button slot="append" @click="materialElementMoveUp(element, idx)" icon="el-icon-arrow-up"></el-button>
                                <el-button slot="append" @click="materialElementMoveDown(element, idx)" icon="el-icon-arrow-down"></el-button>
                                <el-button slot="append" @click="materialElementRemove(element, idx)" icon="el-icon-close"></el-button>
                            </el-input>
                            <el-select
                                v-if="materialSuggestUnitList[idx] && materialSuggestUnitList[idx].length"
                                v-model="element.suggest_unit_name"
                                placeholder="単位"
                                size="mini"
                                class="materialListMainItem__unitElement"
                                @change="setMaterialUnitName(element)">
                                <el-option
                                    v-for="(value, index) in materialSuggestUnitList[idx]"
                                    :key="index"
                                    :label="value"
                                    :value="value">
                                </el-option>
                            </el-select>
                            <el-input
                                v-model="element.url"
                                class="materialListMainItem__urlElement"
                                type="text"
                                size="mini"
                                placeholder="URL"
                            ></el-input>
                        </div>
                        <div class="materialListSubItem">
                            <el-input
                                v-model="element.material_group_name"
                                type="text"
                                size="mini"
                                placeholder="グループ名"
                                class="materialListSubItem__groupElement"
                            ></el-input>
                            <el-input
                                :value="element.material.material_category ? element.material.material_category.material_category_name : ''"
                                type="text"
                                size="mini"
                                disabled
                                placeholder="マスタなし"
                                class="materialListSubItem__categoryElement"
                            ></el-input>
                            <el-checkbox
                                v-model="element.is_main"
                                size="mini"
                                class="materialListSubItem__labelElement"
                            >メイン食材</el-checkbox>
                        </div>
                    </div>
                </el-row>
                <el-form-item>
                    <el-button size="small" @click="addMaterialElement()" type="primary">行を追加する</el-button>
                </el-form-item>
            </el-form-item>

            <div class="recipeSummaryItem__formSectionTitle">コツ・ポイント（強調表示）</div>
            <div class="recipeSummaryItem__caution">「本文」を1つ以上入力することで、作成されます</div>
            <el-form-item label="タイプ">
                <el-select v-model="form.emphasis_type" size="small">
                    <el-option
                        v-for="item in emphasisItemTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="カラー">
                <el-select v-model="form.emphasis_color" size="small">
                    <el-option
                        v-for="item in emphasisItemColorList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="見出し">
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.emphasis_heading"
                ></el-input>
                <text-length-count :targetText="form.emphasis_heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item label="クラス">
                <el-select v-model="form.emphasis_heading_tag_type" size="small">
                    <el-option
                        v-for="item in emphasisItemHeadingTagTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="本文">
                <el-row v-for="(element, idx) in form.emphasis_elements" :key="idx" class="recipeSummaryItem__emphasisElementList">
                    <span class="required"></span>
                    <wysiwyg-editor
                        v-model="element.text"
                        :is-array="true"
                        :editor-total-count="form.emphasis_elements.length"
                        class="recipeSummaryItem__emphasisElementText"
                    ></wysiwyg-editor>
                    <el-row type="flex" class="recipeSummaryItem__recipeElementDeleteWrapper">
                        <i @click="deleteEmphasisElement(idx)" class="el-icon-close recipeSummaryItem__recipeElementActionIcon"></i>
                    </el-row>
                </el-row>
                <el-button @click="addEmphasisElement()" type="primary" size="mini">本文を追加する</el-button>
            </el-form-item>

            <div class="recipeSummaryItem__formSectionTitle">作り方</div>
            <el-form-item label="下ごしらえ大見出し">
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.preparation_heading"
                    placeholder="不要な場合は未記入"
                />
                <text-length-count :targetText="form.preparation_heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item>
                <el-row type="flex" class="recipeSummaryItem__recipeElementWrapper">
                    <span class="recipeSummaryItem__recipeIcon">下</span>
                    <el-input
                        v-model="form.preparation_description"
                        type="text"
                        size="mini"
                        placeholder="下ごしらえ中見出し（不要な場合は未記入）"
                        class="recipeSummaryItem__recipeDescription"
                    ></el-input>
                    <text-length-count :targetText="form.preparation_description" :maxTextLength="100" />
                </el-row>
                <el-row>
                    <div class="recipeSummaryItem__recipeElementContentList">
                        <el-upload
                            class="avatar-uploader recipeSummaryItem__recipeElementContentImage"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handlePreparationImageAdd(file)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="form.preparation_image_cdn_url" :src="form.preparation_image_cdn_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-row class="recipeSummaryItem__recipeElementContentsWrapper">
                            <el-col :span="24">
                                <wysiwyg-editor
                                    v-model="form.preparation_comment"
                                    editorViewStyle="height: 7em;resize: vertical;overflow-y: scroll;word-break: break-all;"
                                    class="recipeSummaryItem__recipeElementContents"
                                    placeholder="補足コメント"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="form.preparation_alt_text"
                                    class="recipeSummaryItem__recipeElementContents"
                                    placeholder="alt属性"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="form.preparation_photo_by"
                                    class="recipeSummaryItem__recipeElementContents"
                                    placeholder="photoBy"
                                />
                            </el-col>
                        </el-row>
                    </div>
                </el-row>
            </el-form-item>
            <el-form-item label="作り方大見出し" required>
                <el-input
                    type="text"
                    size="mini"
                    v-model="form.recipe_heading"
                />
                <text-length-count :targetText="form.recipe_heading" :maxTextLength="100" />
            </el-form-item>
            <el-form-item v-for="(element, idx) in form.recipe_elements" :key="idx">
                <el-row type="flex" class="recipeSummaryItem__recipeElementWrapper">
                    <span class="recipeSummaryItem__recipeIcon">{{ idx + 1 }}</span>
                    <span class="required"></span>
                    <el-input
                        v-model="element.recipe_text"
                        type="text"
                        size="mini"
                        placeholder="作り方中見出し"
                        class="recipeSummaryItem__recipeDescription"
                    ></el-input>
                    <text-length-count :targetText="element.recipe_text" :maxTextLength="100" />
                    <el-row type="flex" class="recipeSummaryItem__recipeElementActionWrapper">
                        <i @click="recipeElementMoveUp(element, idx)" class="el-icon-arrow-up recipeSummaryItem__recipeElementActionIcon"></i>
                        <i @click="recipeElementMoveDown(element, idx)" class="el-icon-arrow-down recipeSummaryItem__recipeElementActionIcon"></i>
                        <i @click="recipeElementRemove(element, idx)" class="el-icon-close recipeSummaryItem__recipeElementActionIcon"></i>
                    </el-row>
                </el-row>
                <el-row>
                    <div v-for="(elementContent, elementContentIdx) in element.contents" class="recipeSummaryItem__recipeElementContentList">
                        <el-upload
                            class="avatar-uploader recipeSummaryItem__recipeElementContentImage"
                            :auto-upload="false"
                            action=""
                            :on-change="(file) => handleRecipeElementContentImageAdd(file, idx, elementContentIdx)"
                            :show-file-list="false"
                            accept="image/png,image/jpeg,image/gif"
                        >
                            <img v-if="elementContent.image_cdn_url" :src="elementContent.image_cdn_url" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-row class="recipeSummaryItem__recipeElementContentsWrapper">
                            <el-col :span="24">
                                <wysiwyg-editor
                                    v-model="elementContent.description"
                                    placeholder="補足コメント"
                                    editorViewStyle="height: 7em;resize: vertical;overflow-y: scroll;word-break: break-all;"
                                    class="recipeSummaryItem__recipeElementContents"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="elementContent.alt_text"
                                    class="recipeSummaryItem__recipeElementContents"
                                    placeholder="alt属性"
                                />
                            </el-col>
                            <el-col :span="24">
                                <el-input
                                    type="text"
                                    size="mini"
                                    v-model="elementContent.photo_by"
                                    class="recipeSummaryItem__recipeElementContents"
                                    placeholder="photoBy"
                                />
                            </el-col>
                        </el-row>
                        <el-row type="flex" class="recipeSummaryItem__recipeElementDeleteWrapper">
                            <i @click="deleteRecipeElementContent(idx, elementContentIdx)" class="el-icon-close recipeSummaryItem__recipeElementActionIcon"></i>
                        </el-row>
                    </div>
                    <el-button @click="addRecipeElementContent(idx)" type="primary" size="mini">要素を追加する</el-button>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button @click="addRecipeElement()" type="primary" size="mini">作り方を追加する</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="itemFormFooter">
            <el-button type="primary" size="mini" @click="submitForm">保存する</el-button>
            <el-button size="mini" @click="$emit('close')">キャンセル</el-button>
        </span>
    </div>
</template>

<script>
import WysiwygEditor from '../Components/WysiwygEditor'
import TextLengthCount from '../Components/TextLengthCount'
import * as types from '../../../../../../store/mutation-types'
const defaultFormValues = () => ({
    id: null,
    basic_heading: null,
    basic_image_file: null,
    basic_image_cdn_url: null,
    basic_alt_text: null,
    basic_photo_by: null,
    basic_description: null,
    cooking_time: null,
    total_cooking_time: 0,
    cooking_time_comment: null,
    calorie: null,
    protein: null,
    lipid: null,
    carbohydrate: null,
    salt: null,
    saccharinity: null,
    nutrient_comment: null,
    material_heading: '材料',
    material_image_file: null,
    material_image_cdn_url: null,
    material_alt_text: null,
    material_photo_by: null,
    material_elements: [],
    servings_min: null,
    servings_max: null,
    servings_free_text: null,
    emphasis_type: null,
    emphasis_color: null,
    emphasis_heading: 'コツ・ポイント',
    emphasis_heading_tag_type: null,
    emphasis_elements: [],
    recipe_elements: [],
    preparation_heading: null,
    preparation_image_file: null,
    preparation_image_cdn_url: null,
    preparation_alt_text: null,
    preparation_photo_by: null,
    preparation_description: null,
    preparation_comment: null,
    recipe_heading: '作り方',
})
const defaultMaterialElementValues = () => ({
    id: null,
    material_group_name: null,
    quantity_text: null,
    url: null,
    is_main: false,
    material: {
        material_name: null,
        material_category: {
            material_category_name: null,
        }
    },
})
const defaultEmphasisElementValues = () => ({
    id: null,
    text: null,
})
const defaultRecipeElementValues = () => ({
    id: null,
    recipe_text: null,
    contents: [],
})
const defaultRecipeElementContentValues = () => ({
    id: null,
    image_file: null,
    image_cdn_url: null,
    alt_text: null,
    photo_by: null,
    description: null,
})
export default {
    components: {
        WysiwygEditor,
        TextLengthCount,
    },
    props: {
        save: {
            required: true,
            type: Function,
        },
        emphasisItemHeadingTagTypeList: {
            required: true,
            type: Array,
        },
        emphasisItemColorList: {
            required: true,
            type: Array,
        },
        emphasisItemTypeList: {
            required: true,
            type: Array,
        },
    },
    data() {
        return {
            form: {},
            findArticle: null,
            imageRequestHeader: {
                'Authorization': 'Bearer ' + localStorage.getItem('Authorization.token'),
            },
            materialFocusedLineIndex: null,
            materialSuggestUnitList: [],
            emphasisElementMaxCount: 10,
        }
    },
    created() {
        this.form = defaultFormValues()
        this.$set(this.form, 'material_elements', [])
        this.form.material_elements.push(defaultMaterialElementValues())
        this.$set(this.form, 'emphasis_elements', [])
        this.$set(this.form, 'recipe_elements', [])
        this.form.recipe_elements.push(defaultRecipeElementValues())
        this.$set(this.form.recipe_elements[0], 'contents', [])
        this.form.recipe_elements[0].contents.push(defaultRecipeElementContentValues())
    },
    methods: {
        submitForm () {
            const fd = new FormData()
            fd.append('basic_heading', this.form.basic_heading ?? '')
            fd.append('basic_image_file', this.form.basic_image_file ?? '')
            fd.append('basic_alt_text', this.form.basic_alt_text ?? '')
            fd.append('basic_photo_by', this.form.basic_photo_by ?? '')
            fd.append('basic_description', this.form.basic_description ?? '')
            fd.append('cooking_time', this.form.cooking_time)
            fd.append('total_cooking_time', this.form.total_cooking_time)
            fd.append('cooking_time_comment', this.form.cooking_time_comment ?? '')
            fd.append('calorie', this.form.calorie)
            fd.append('protein', this.form.protein)
            fd.append('lipid', this.form.lipid)
            fd.append('carbohydrate', this.form.carbohydrate)
            fd.append('salt', this.form.salt)
            fd.append('saccharinity', this.form.saccharinity)
            fd.append('nutrient_comment', this.form.nutrient_comment ?? '')
            fd.append('material_heading', this.form.material_heading ?? '')
            fd.append('material_image_file', this.form.material_image_file ?? '')
            fd.append('material_alt_text', this.form.material_alt_text ?? '')
            fd.append('material_photo_by', this.form.material_photo_by ?? '')
            fd.append('servings_min', this.form.servings_min)
            fd.append('servings_max', this.form.servings_max)
            fd.append('servings_free_text', this.form.servings_free_text ?? '')
            fd.append('emphasis_type', this.form.emphasis_type ?? '')
            fd.append('emphasis_color', this.form.emphasis_color ?? '')
            fd.append('emphasis_heading', this.form.emphasis_heading ?? '')
            fd.append('emphasis_heading_tag_type', this.form.emphasis_heading_tag_type ?? '')
            fd.append('preparation_heading', this.form.preparation_heading ?? '')
            fd.append('preparation_image_file', this.form.preparation_image_file ?? '')
            fd.append('preparation_alt_text', this.form.preparation_alt_text ?? '')
            fd.append('preparation_photo_by', this.form.preparation_photo_by ?? '')
            fd.append('preparation_description', this.form.preparation_description ?? '')
            fd.append('preparation_comment', this.form.preparation_comment ?? '')
            fd.append('recipe_heading', this.form.recipe_heading ?? '')
            this.form.material_elements.map((element, idx) => {
                fd.append(`material_elements[${idx}][id]`, element.id ?? '')
                fd.append(`material_elements[${idx}][material_name]`, element.material.material_name ?? '')
                fd.append(`material_elements[${idx}][material_group_name]`, element.material_group_name ?? '')
                fd.append(`material_elements[${idx}][quantity_text]`, element.quantity_text ?? '')
                fd.append(`material_elements[${idx}][url]`, element.url ?? '')
                fd.append(`material_elements[${idx}][is_main]`, element.is_main ? 1 : 0)
            })
            this.form.emphasis_elements.map((element, idx) => {
                fd.append(`emphasis_elements[${idx}][id]`, element.id ?? '')
                fd.append(`emphasis_elements[${idx}][text]`, element.text ?? '')
            })
            this.form.recipe_elements.map((element, idx) => {
                fd.append(`recipe_elements[${idx}][id]`, element.id ?? '')
                fd.append(`recipe_elements[${idx}][recipe_text]`, element.recipe_text ?? '')
                element.contents.map((elementContent, elementContentIdx) => {
                    fd.append(`recipe_elements[${idx}][contents][${elementContentIdx}][id]`, elementContent.id ?? '')
                    fd.append(`recipe_elements[${idx}][contents][${elementContentIdx}][image_file]`, elementContent.image_file ?? '')
                    fd.append(`recipe_elements[${idx}][contents][${elementContentIdx}][alt_text]`, elementContent.alt_text ?? '')
                    fd.append(`recipe_elements[${idx}][contents][${elementContentIdx}][photo_by]`, elementContent.photo_by ?? '')
                    fd.append(`recipe_elements[${idx}][contents][${elementContentIdx}][description]`, elementContent.description ?? '')
                })
            })
            this.save('recipe_summaries', fd, this.id)
        },
        handleBasicImageAdd(file) {
            this.form.basic_image_file = file.raw
            this.form.basic_image_cdn_url = URL.createObjectURL(file.raw)
        },
        handleMaterialImageAdd(file) {
            this.form.material_image_file = file.raw
            this.form.material_image_cdn_url = URL.createObjectURL(file.raw)
        },
        addEmphasisElement() {
            if (this.form.emphasis_elements.length >= this.emphasisElementMaxCount) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, `最大${this.emphasisElementMaxCount}件です`)
                return
            }
            this.form.emphasis_elements.push(defaultEmphasisElementValues())
        },
        deleteEmphasisElement(idx) {
            this.form.emphasis_elements.splice(idx, 1)
        },
        focusMaterialSuggestList(idx) {
            this.$set(this.materialSuggestUnitList, idx, [])
            this.materialFocusedLineIndex = idx
        },
        showMaterialSuggestUnit(item) {
            this.$set(this.materialSuggestUnitList, this.materialFocusedLineIndex, item.unit_name)
            this.$set(this.form.material_elements[this.materialFocusedLineIndex], 'url', item.url)
        },
        materialElementMoveDown(element, idx) {
            const movedIdx = idx + 1
            if (this.form.material_elements[movedIdx]) {
                this.form.material_elements.splice(idx, 2, this.form.material_elements[movedIdx], this.form.material_elements[idx])
            }
        },
        materialElementMoveUp(element, idx) {
            if (idx > 0) {
                const movedIdx = idx - 1
                this.form.material_elements.splice(movedIdx, 2, this.form.material_elements[movedIdx+1], this.form.material_elements[movedIdx])
            }
        },
        materialElementRemove(element, idx) {
            if (this.form.material_elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.form.material_elements.splice(idx, 1)
        },
        setMaterialUnitName(element) {
            element.quantity_text = element.suggest_unit_name
            element.suggest_unit_name = null
        },
        addMaterialElement() {
            this.form.material_elements.push(defaultMaterialElementValues())
            this.materialSuggestUnitList.push([])
        },
        async suggestMaterial(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/articles/items/materials/suggest', {
                    params: {
                        suggest_word: queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'URL検索に失敗しました')
                    return
                }
                cb(data.suggest_materials)
            }
        },
        recipeElementMoveDown(element, idx) {
            const movedIdx = idx + 1
            if (this.form.recipe_elements[movedIdx]) {
                this.form.recipe_elements.splice(idx, 2, this.form.recipe_elements[movedIdx], this.form.recipe_elements[idx])
            }
        },
        recipeElementMoveUp(element, idx) {
            if (idx > 0) {
                const movedIdx = idx - 1
                this.form.recipe_elements.splice(movedIdx, 2, this.form.recipe_elements[movedIdx+1], this.form.recipe_elements[movedIdx])
            }
        },
        recipeElementRemove(element, idx) {
            if (this.form.recipe_elements.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.form.recipe_elements.splice(idx, 1)
        },
        handlePreparationImageAdd(file) {
            this.$set(this.form, 'preparation_image_file', file.raw)
            this.$set(this.form, 'preparation_image_cdn_url', URL.createObjectURL(file.raw))
        },
        handleRecipeElementContentImageAdd(file, idx, contentIdx) {
            this.$set(this.form.recipe_elements[idx].contents[contentIdx], 'image_file', file.raw)
            this.$set(this.form.recipe_elements[idx].contents[contentIdx], 'image_cdn_url', URL.createObjectURL(file.raw))
            this.fetchedImage = true
        },
        addRecipeElement() {
            const addValue = defaultRecipeElementValues()
            this.$set(addValue, 'contents', [])
            addValue.contents.push(defaultRecipeElementContentValues())
            this.form.recipe_elements.push(addValue)
        },
        addRecipeElementContent(idx) {
            this.form.recipe_elements[idx].contents.push(defaultRecipeElementContentValues())
        },
        deleteRecipeElementContent(idx, contentIdx) {
            if (this.form.recipe_elements[idx].contents.length <= 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '最低1つ必要です。')
                return
            }
            this.form.recipe_elements[idx].contents.splice(contentIdx, 1)
        }
    },
}
</script>
<style scoped lang="scss">
.recipeSummaryItem {
    &__formSectionTitle {
        font-weight: bold;
        margin-bottom: 15px;
    }
    &__recipeElementContentList {
        display: flex;
        margin-bottom: 10px;
    }
    &__recipeElementContentImage {
        margin-right: 15px;
    }
    &__recipeElementContentsWrapper {
        margin-right: 5px;
        flex-grow: 1;
    }
    &__recipeElementContents {
        margin-bottom: 5px;
    }
    &__recipeIcon {
        margin: 0 15px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        background-color: #E3D099;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        box-sizing: content-box;
        padding: 5px;
        border-radius: 50%;
    }
    &__recipeElementActionWrapper {
        width: 150px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
        margin: 0 20px;
        cursor: pointer;
    }
    &__recipeElementActionIcon {
        width: 50px;
        height: 100%;
        background: #F5F7FA;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8F9398;
        cursor: pointer;
    }
    &__recipeElementWrapper {
        margin-bottom: 10px;
    }
    &__emphasisElementList {
        display: flex;
        align-content: center;
        margin-bottom: 10px;
    }
    &__emphasisElementText {
        flex: 1;
        margin-right: 10px;
    }
    &__innerFormContent {
        margin-bottom: 10px;
    }
    &__innerFormLabel {
        margin-bottom: 5px;
    }
    &__recipeDescription {
        width: 300px;
    }
    &__recipeElementDeleteWrapper {
        width: 50px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
    }
    &__caution {
        font-size: 12px;
        color: red;
    }
    &__imageWrapper {
        display: flex;
    }
    &__imageContents {
        margin-bottom: 5px;
    }
    &__image {
        margin-right: 15px;
    }
}

.materialList {
    display: flex;
    flex-direction: column;
    margin: 0 0 12px 0;
}
.materialListMainItem {
    display: flex;
    &__formElement {
        width: 129px;
        margin: 0 5px 0 0;
    }
    &__quantityElement {
        width: 186px;
        margin: 0 5px 0 0;
    }
    &__unitElement {
        width: 106px;
        margin: 0 5px 0 0;
    }
    &__urlElement {
        width: 106px;
    }
}
.materialListSubItem {
    display: flex;
    margin: 5px 0 0 0;
    padding: 0 0 0 144.71px;
    &__groupElement {
        width: 186px;
        margin: 0 5px 0 0;
    }
    &__categoryElement {
        width: 106px;
        margin: 0 5px 0 0;
    }
    &__labelElement {
        width: 106px;
    }
}

.required::before {
    content: '*';
    color: #F56C6C;
    margin-right: 4px;
}
</style>
