<template>
    <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
        <el-table-column size="mini" width="60" label="記事ID" >
            <template slot-scope="scope">
                {{ scope.row.article.id }}
            </template>
        </el-table-column>
        <el-table-column size="small" label="執筆ライター" >
            <template slot-scope="scope">
                {{ scope.row.article.writer.writer_name }}
            </template>
        </el-table-column>
        <el-table-column width="170" label="公開ライター" >
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.article.publish_writer.writer_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchWriter"
                    @focus="focusSuggest(scope.$index)"
                    @select="setPublishWriterId"
                    placeholder="公開ライター"
                ></el-autocomplete>
            </template>
        </el-table-column>
        <el-table-column size="small" label="記事タイトル" >
            <template slot-scope="scope">
                {{ scope.row.article.title }}
            </template>
        </el-table-column>

        <el-table-column size="small" label="記事ステータス" >
            <template slot-scope="scope">
                {{ articleStatusLabelList[scope.row.article.status] }}
            </template>
        </el-table-column>

        <el-table-column size="small" width="135" :render-header="renderTableHeader(['CCD最新日時','CCD送信日時','CCDステータス','CCD回数'])" >
            <template slot-scope="scope">
                {{ (scope.row.article.ccd && scope.row.article.ccd.last_checked_at) ? scope.row.article.ccd.last_checked_at : '-' }}
                <br>
                {{ (scope.row.article.ccd && scope.row.article.ccd.created_at) ? scope.row.article.ccd.created_at : '-' }}
                <br>
                <template v-if="scope.row.ccd_status === 100">
                    <el-select v-model="scope.row.article.ccd.status" size="mini">
                        <el-option
                                v-for="(item) in ccdEditableStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </template>
                <template v-else>
                    {{ scope.row.ccd_status !== null ?
                    ccdStatusLabelList[scope.row.ccd_status] : '-' }}
                    <br>
                </template>
                {{ (scope.row.article.ccd && scope.row.article.ccd.check_count) ? scope.row.article.ccd.check_count : '-' }}
            </template>
        </el-table-column>
        <el-table-column size="small" width="120"
                         :render-header="renderTableHeader(['編集者','編集ステータス'])" >
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.editor_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchAdmin"
                    @focus="focusSuggest(scope.$index)"
                    @select="setEditingAdminId"
                    placeholder="編集者"
                ></el-autocomplete>
                <br>
                <el-select v-model="scope.row.status" size="mini">
                    <el-option
                        v-for="(item) in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column size="medium" width="135" :render-header="renderTableHeader(['最終編集日時','公開予約日時','支払いチェック'])"  >
            <template slot-scope="scope">
                {{ scope.row.updated_at }}
                <br>
                <el-date-picker
                    v-model="scope.row.reserved_at"
                    type="datetime"
                    style="width:100%"
                    size="mini"
                    :disabled="(scope.row.status !== 300)"
                >
                </el-date-picker>
                <el-button
                    class="btnEdit checkBtn"
                    type="primary"
                    size="mini"
                    @click="doPaymentCheck(scope.row)"
                >支払いチェック</el-button>
                <div v-if="scope.row.payment_check">
                    {{scope.row.payment_check.created_at}}<br />
                    {{scope.row.payment_check.char_count}}文字
                </div>
            </template>
        </el-table-column>
        <el-table-column :width="280" label="コメント" >
            <template slot-scope="scope">
                <el-input
                    type="textarea"
                    :rows="10"
                    :cols="40"
                    v-model="scope.row.comment">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column size="small" align="center" label="操作" >
            <template slot-scope="scope">
                <el-button
                    class="btnEdit unitBtn"
                    type="warning"
                    size="mini"
                    @click="doUpdate(scope.row)"
                >更新</el-button>
                <a :href="addArticleUrl(scope.row.article.id)" target="_blank" >記事作成</a>
                <br>
                <a v-if="scope.row.preview_url !== ''" :href="scope.row.preview_url" target="_blank">プレビュー</a>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>

export default {
    name: 'CheckRow',
    props: {
        tableData: Array,
        ccdStatusLabelList: Array,
        ccdEditableStatus: Array,
        articleStatusLabelList: Array,
        statusList: Array,
        update: Function,
        paymentCheck: Function
    },
    data () {
        return {
            focusedLineIndex: null
        }
    },
    methods: {
        isArticleAdd (status) {
            return (status === 100) ? true : false
        },
        addArticleUrl (articleId) {
            return '/articles/' + articleId
        },
        doUpdate (form) {
            this.update(form)
        },
        doPaymentCheck (form) {
            this.paymentCheck(form)
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            } else if (typeof this.tableData[this.focusedLineIndex]['article']['direction']['editing_admin_id'] === 'number') {
                this.tableData[this.focusedLineIndex]['article']['direction']['editing_admin_id'] = null
            }
        },
        async querySearchWriter(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
        focusSuggest(index) {
            this.focusedLineIndex = index
        },
        setPublishWriterId(item) {
            this.tableData[this.focusedLineIndex]['article']['publish_writer']['id'] = item.writer_id
        },
        setEditingAdminId(item) {
            this.tableData[this.focusedLineIndex]['article']['direction']['editing_admin_id'] = item.admin_id
        },
    }
}
</script>

<style>
    .el-table td, th{
        vertical-align: top !important;
    }
</style>

<style scoped>
    .el-button {
        margin: 5px 0 5px 10px;
    }

    .unitBtn {
        width: 100%;
    }

    .checkBtn {
        margin-top:20px;
        margin-left:0;
        width: 100%;
    }

    .list {
        vertical-align: top;
    }

    .el-select {
        margin: 5px 0 5px 10px;
    }
    .el-autocomplete {
        margin: 5px 0 5px 10px;
    }
</style>
