<template>
    <el-dialog
        title="単位選択"
        :visible.sync="isActive"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="close"
    >
        <el-form @submit.native.prevent label-width="140px" >
            <span v-for="(materialUnit, idx) in materialUnits">
                <el-checkbox v-model="selectMaterialUnitIndexes[idx]" style="width: 120px;">{{ materialUnit.name }}</el-checkbox>
            </span>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">閉じる</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    props: {
        isActive: Boolean,
        materialUnits: Array,
        material: Object,
    },
    data() {
        return {
            selectMaterialUnitIndexes: [],
        }
    },
    methods: {
        close() {
            const vue = this
            const selectMaterialUnits = []
            this.selectMaterialUnitIndexes.forEach((value, idx) => {
                if (value) {
                    selectMaterialUnits.push(vue.materialUnits[idx])
                }
            })

            this.$emit('close', selectMaterialUnits)
        }
    },
    watch: {
        material() {
            const vue = this
            this.selectMaterialUnitIndexes = Object.assign([], this.materialUnits)
            this.selectMaterialUnitIndexes.forEach((materialUnit, materialUnitIdx) => {
                vue.selectMaterialUnitIndexes[materialUnitIdx] = false
            })
            this.material.suggest_unit.forEach((unit) => {
                vue.materialUnits.forEach((materialUnit, materialUnitIdx) => {
                    if (unit.id === materialUnit.id) {
                        vue.selectMaterialUnitIndexes[materialUnitIdx] = true
                    }
                })
            })
        }
    },
}
</script>
