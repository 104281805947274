<template>
    <el-card class="topCard">
        <el-form @submit.native.prevent label-width="85px" >
            <el-row>
                <el-col :span="8">
                    <el-form-item label="記事種別" >
                        <el-select @change="update" v-model="form.article_type_id" size="mini">
                            <el-option
                                v-for="item in articleTypeList"
                                :key="item.id"
                                :label="item.type_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="カテゴリ" style="margin-bottom: 10px;">
                        <el-select @change="changeCategory" :value="form.category_id" v-model="form.category_id" size="mini">
                            <el-option
                                v-for="item in categoryList"
                                :key="item.id"
                                :label="item.category_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="監修者" style="margin-bottom: 10px;" v-if="isTr">
                        <el-select @change="update" v-model="form.supervisor_writer_id" size="mini" clearable filterable placeholder="選択しない">
                            <el-option
                                v-for="item in supervisorList"
                                :key="item.id"
                                :label="item.writer_name"
                                :value="item.id">
                                <span style="float: left">{{ item.writer_name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 12px;">{{ item.screen_name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="親タグ">
                        <tag-list :tags="parentTags" :input="handleInputParentTag" :close="handleCloseParentTag" :suggestList='suggestParentTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="子タグ">
                        <tag-list :tags="childTags" :input="handleInputChildTag" :close="handleCloseChildTag" :suggestList='suggestChildTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="孫タグ" >
                        <tag-list :tags="grandchildTags" :input="handleInputGrandchildTag" :close="handleCloseGrandchildTag" :suggestList='suggestGrandchildTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8" v-if="isTr">
                    <el-form-item label="記事管理" >
                        <el-checkbox v-model="form.is_trend" @change="update">トレンド記事</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="isTr">
                    <el-checkbox v-model="form.is_mate" @change="update">Mate記事</el-checkbox>
                </el-col>
                <el-col :span="8" v-if="isTr">
                    <el-checkbox v-model="form.is_original" @change="update">オリジナル記事</el-checkbox>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8" v-if="article.format === 'normal'">
                    <el-form-item label="レシピ区分" style="margin-bottom: 10px;">
                        <el-select @change="update" :value="form.recipe_type" v-model="form.recipe_type" size="mini">
                            <el-option value=""></el-option>
                            <el-option
                                v-for="item in articleRecipeTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script>
import * as types from '../../../store/mutation-types'
import TagList from './ArticleBaseTable/TagList'

const formDefaultData = {
    article_type_id: null,
    supervisor_writer_id: null,
    category_id: null,
    is_trend: false,
    is_original: false,
    is_mate: false,
    parent_tags: [],
    child_tags: [],
    grandchild_tags: [],
    recipe_type: null,
}
export default {
    name:'ArticleBaseTable',
    props: {
        article: Object,
        categoryList: Array,
        articleTypeList: Array,
        supervisorList: Array,
        tagList: Array,
        fetch: Function,
        isTr: Boolean,
        articleRecipeTypeList: Array,
    },
    components: {
        TagList
    },
    data() {
        return {
            parentTags: [],
            childTags: [],
            grandchildTags: [],
            is_mate: false,
            is_original: false,
            is_trend: false,
        }
    },
    mounted() {
        this.parentTags = this.form.parent_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
        this.childTags = this.form.child_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
        this.grandchildTags = this.form.grandchild_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
    },
    computed:{
        form() {
            return Object.assign({}, formDefaultData, _.pick(this.article, Object.keys(formDefaultData)))
        },
        suggestParentTagList() {
            return this.tagList.map(tag => ({id: tag.id, label: tag.tag_name}))
        },
        suggestChildTagList() {
            return this.tagList
                .filter(parentTag => this.parentTags.some(selectParentTag => selectParentTag.id === parentTag.id))
                .flatMap(parentTag => parentTag.children)
                .map(childTag => {
                    const index = this.parentTags.findIndex(parentTag => parentTag.id === childTag.parent_tag_id)
                    return {id: childTag.id, label: childTag.tag_name, parent: this.parentTags[index].label}
                })
        },
        suggestGrandchildTagList() {
            return this.tagList
                .filter(parentTag => this.parentTags.some(selectParentTag => selectParentTag.id === parentTag.id))
                .flatMap(parentTag => parentTag.children)
                .filter(childTag => this.childTags.some(selectChildTag => selectChildTag.id === childTag.id))
                .flatMap(child => child.grandchildren)
                .map(grandchildTag => {
                    const childTagIndex = this.childTags.findIndex(childTag => childTag.id === grandchildTag.child_tag_id)
                    const parentTagIndex = this.parentTags.findIndex(parentTag => {
                        const childTag = this.tagList.flatMap(parentTag => parentTag.children).filter(tag => tag.id === this.childTags[childTagIndex].id)[0]
                        return parentTag.id === childTag.parent_tag_id
                    })
                    return {id: grandchildTag.id, label: grandchildTag.tag_name, parent: this.parentTags[parentTagIndex].label, child: this.childTags[childTagIndex].label}
                })
        },
    },
    methods: {
        async update() {
            this.$store.commit(types.EDITOR_LOADING, true)
            this.form.tags = {parents: this.parentTags, children: this.childTags, grandchildren: this.grandchildTags}
            const {error} = await axios.patch('/api/articles/' + this.article.id + '/base', this.form)
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                await this.fetch()
            }
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        handleInputParentTag(value) {
            if (value && this.parentTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.find(item => item.id === value)
                this.parentTags.push({id: tag.id, label: tag.tag_name})
                this.update()
            }
        },
        handleInputChildTag(value) {
            if (value && this.childTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children).find(item => item.id === value)
                this.childTags.push({id: tag.id, label: tag.tag_name})
                this.update()
            }
        },
        handleInputGrandchildTag(value) {
            if (value && this.grandchildTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children)
                    .flatMap(child => child.grandchildren)
                    .find(item => item.id === value)
                this.grandchildTags.push({id: tag.id, label: tag.tag_name})
                this.update()
            }
        },
        handleCloseParentTag(index) {
            if (this.parentTags.length === 1) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, '親タグは必須です。')
                return
            }
            this.tagList.filter(tag => {
                return (this.parentTags[index].id === tag.id)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    const index = this.childTags.findIndex(tag => tag.id === childTag.id)
                    if (index !== -1) {
                        this.childTags.splice(index, 1)
                    }
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = this.grandchildTags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            this.grandchildTags.splice(index, 1)
                        }
                    })
                })
            })
            this.parentTags.splice(index, 1)
            this.update()
        },
        handleCloseChildTag(index) {
            this.tagList.filter(parentTag => {
                const childTag = parentTag.children.filter(childTag => this.childTags[index].id === childTag.id)
                return (childTag.length !== 0)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = this.grandchildTags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            this.grandchildTags.splice(index, 1)
                        }
                    })
                })
            })
            this.childTags.splice(index, 1)
            this.update()
        },
        handleCloseGrandchildTag(index) {
            this.grandchildTags.splice(index, 1)
            this.update()
        },
        changeCategory() {
            this.update()
        }
    },
}
</script>
<style scoped>
.el-card.is-always-shadow.topCard {
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 15px;
}
</style>
