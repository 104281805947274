import Vue from 'vue'
import Router from 'vue-router'

import Top from './pages/Top'
import Login from './pages/Login'
import Topic from './pages/Topic/Topic'
import TopicArticleEdit from './pages/Topic/ArticleEdit'
import AppTab from './pages/Organization/AppTab'
import AppTabOrder from './pages/Organization/AppTab/Order'
import Article from './pages/Article'
import AppSearchWord from './pages/Organization/AppSearchWord'
import AppSearchWordOrder from './pages/Organization/AppSearchWord/Order'
import AppTopic from './pages/Organization/AppTopic'
import AppTopicOrder from './pages/Organization/AppTopic/Order'
import AppTopicArticle from './pages/Organization/AppTopic/Article'
import AppTopicArticleOrder from './pages/Organization/AppTopic/Article/Order'
import AppRecommendMaterial from './pages/Organization/AppRecommendMaterial'
import AppSearchGenre from './pages/Organization/AppSearchGenre'
import Down from './pages/Article/Down'
import NotFound from './pages/NotFound'
import Fee from './pages/Writer/Fee'
import FeeMonthly from './pages/Writer/Fee/Monthly'
import FeeArticle from './pages/Writer/Fee/Article'
import Whitelist from './pages/Management/Url/Whitelist'
import Blacklist from './pages/Management/Url/Blacklist'
import SnsAccountBlacklist from './pages/Management/Quote/SnsAccount/Blacklist'
import Admin from './pages/Admin/Admin'
import VerifyEmail from './pages/VerifyEmail'
import Serialization from './pages/Serialization/Serialization'
import SerializationArticleEdit from './pages/Serialization/ArticleEdit'
import Export from './pages/Management/Quote/Export'
import User from './pages/User/User'
import Writer from './pages/Writer/Writer'
import WriterEditor from './pages/Writer/Editor'
import Shutterstock from './pages/Management/Quote/Shutterstock'
import Tag from './pages/Management/Tag'
import Ng from './pages/Editing/Ng'
import ReprintMedia from './pages/Reprint/Media'
import Direction from './pages/Editing/Direction'
import Check from './pages/Editing/Check'
import CheckRewrite from './pages/Editing/CheckRewrite'
import SmartphoneNotification from './pages/Smartphone/Notification'
import CcdExcludeDomain from './pages/ExternalRelation/CcdExcludeDomain'
import Plot from './pages/Editing/Plot'
import Rewrite from './pages/Editing/Rewrite'
import RewritePlot from './pages/Editing/RewritePlot'
import OrganizationManage from './pages/Organization/Manage'
import AppNotification from './pages/App/Notification/Notification'
import Review from './pages/Review/Review'
import Form from './pages/Form/Form'
import TieupArticle from './pages/Article/Tieup/Tieup'
import VideoArticle from './pages/Article/Video'
import ArticleEditor from './pages/Article/Editor'
import AppAdArticle from './pages/Organization/AppAdArticle'
import AdTag from './pages/AdTag/AdTag'
import MilMovie from './pages/Management/MilMovie'
import Material from './pages/Management/Material'
import MaterialUnit from './pages/Management/MaterialUnit'
import IncentiveArticle from './pages/Incentive/Article'
import AffinityArticle from './pages/App/AffinityArticle/AffinityArticle'
import Campaign from './pages/Organization/Campaign'
import Question from './pages/Question/Question'
import AppBasicRecipe from './pages/Organization/AppBasicRecipe'
import AppBasicRecipeOrder from './pages/Organization/AppBasicRecipe/Order'
import AppRecipeRecommendSerialization from './pages/Organization/AppRecipeRecommendSerialization'
import AppRecipeSmallTopic from './pages/Organization/AppRecipeSmallTopic'
import RssEdit from './pages/Editing/RssEdit'
import AffiliateSaleInfo from './pages/Management/AffiliateSaleInfo'
import RecipeDb from './pages/Management/RecipeDb'
import TagOperation from './pages/TagOperation/TagOperation'
import TagOperationEdit from './pages/TagOperation/TagOperationEdit'
import AppRecipeMenuHint from './pages/Organization/AppRecipeMenu/Hint'
import AppRecipeMenuList from './pages/Organization/AppRecipeMenu/List'
import AppRecipeMenuArticle from './pages/Organization/AppRecipeMenu/Article'
import AppRecipeMenuArticleOrder from './pages/Organization/AppRecipeMenu/Article/Order'
import AppRecipeMenuHintOrder from './pages/Organization/AppRecipeMenu/Hint/Order'
import Category from './pages/Category/Category'
import CategoryEdit from './pages/Category/CategoryEdit'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        { path: '/login', component: Login, meta: {notRequireLogin: true}},
        { path: '/', component: Top },
        { path: '/articles', component: Article },
        { path: '/articles/down', component: Down },
        { path: '/organizations/manage', component: OrganizationManage },
        { path: '/articles/tieup', component: TieupArticle },
        { path: '/articles/video', component: VideoArticle },
        { path: '/articles/:id', component: ArticleEditor },
        { path: '/organizations/serialization', component: Serialization },
        { path: '/organizations/serialization/:serializationId', component: SerializationArticleEdit },
        { path: '/organizations/topic', component: Topic },
        { path: '/organizations/topic/:topicId', component: TopicArticleEdit },
        { path: '/organizations/app_notification', component: AppNotification },
        { path: '/organizations/app_tab', component: AppTab },
        { path: '/organizations/app_tab/order', component: AppTabOrder },
        { path: '/organizations/app_search_word', component: AppSearchWord },
        { path: '/organizations/app_search_word/order', component: AppSearchWordOrder },
        { path: '/organizations/app_topic', component: AppTopic },
        { path: '/organizations/app_topic/order', component: AppTopicOrder },
        { path: '/organizations/app_topic/:appTopicId/articles', component: AppTopicArticle },
        { path: '/organizations/app_topic/:appTopicId/articles/order', component: AppTopicArticleOrder },
        { path: '/organizations/app_affinity_article', component: AffinityArticle },
        { path: '/organizations/campaign', component: Campaign },
        { path: '/organizations/app_recommend_material', component: AppRecommendMaterial },
        { path: '/organizations/app_basic_recipe', component: AppBasicRecipe },
        { path: '/organizations/app_basic_recipe/order', component: AppBasicRecipeOrder },
        { path: '/organizations/app_search_genre', component: AppSearchGenre },
        { path: '/organizations/app_recipe_recommend_serialization', component: AppRecipeRecommendSerialization },
        { path: '/organizations/app_recipe_small_topic', component: AppRecipeSmallTopic },
        { path: '/organizations/app_recipe_menu/hints', component: AppRecipeMenuHint },
        { path: '/organizations/app_recipe_menu/hints/order', component: AppRecipeMenuHintOrder },
        { path: '/organizations/app_recipe_menu/lists', component: AppRecipeMenuList },
        { path: '/organizations/app_recipe_menu/lists/:appRecipeMenuListId/articles', component: AppRecipeMenuArticle },
        { path: '/organizations/app_recipe_menu/lists/:appRecipeMenuListId/articles/order', component: AppRecipeMenuArticleOrder },
        { path: '/writers/:writerId/fees/articles/:date', component: FeeArticle },
        { path: '/writers/:writerId/fees', component: Fee },
        { path: '/writers/fees/monthly', component: FeeMonthly },
        { path: '/managements/whitelist', component: Whitelist },
        { path: '/managements/blacklist', component: Blacklist },
        { path: '/managements/restrict_sns', component: SnsAccountBlacklist },
        { path: '/managements/admin', component: Admin, meta: { notRequireLogin: true } },
        { path: '/email/verify/:token', component: VerifyEmail, meta: { notRequireLogin: true } },
        { path: '/managements/export', component: Export },
        { path: '/managements/user', component: User },
        { path: '/managements/writer', component: Writer },
        { path: '/managements/writer/:writerId', component: WriterEditor },
        { path: '/managements/shutterstock', component: Shutterstock },
        { path: '/managements/tags', component: Tag },
        { path: '/managements/mil_movies', component: MilMovie },
        { path: '/managements/material', component: Material },
        { path: '/managements/material_unit', component: MaterialUnit },
        { path: '/editings/directions', component: Direction },
        { path: '/editings/checks', component: Check },
        { path: '/editings/ng', component: Ng },
        { path: '/editings/plots', component: Plot },
        { path: '/editings/rewrite_checks', component: CheckRewrite },
        { path: '/reprints', component: ReprintMedia },
        { path: '/organizations/smartphone/notifications', component: SmartphoneNotification },
        { path: '/external_relations/ccd_exclude_domain', component: CcdExcludeDomain },
        { path: '/editings/rewrite', component: Rewrite },
        { path: '/editings/rewrite_plots', component: RewritePlot },
        { path: '/reviews', component: Review },
        { path: '/forms', component: Form },
        { path: '/organizations/app_ad_articles', component: AppAdArticle },
        { path: '/ad_tag', component:  AdTag},
        { path: '/incentives/article', component: IncentiveArticle },
        { path: '/questions', component: Question },
        { path: '/rss_edit', component: RssEdit },
        { path: '/managements/affiliate_sale_info', component: AffiliateSaleInfo },
        { path: '/managements/recipe_db', component: RecipeDb },
        { path: '/tag_operations', component: TagOperation },
        { path: '/tag_operations/:tag_path_id', component: TagOperationEdit },
        { path: '/categories', component: Category },
        { path: '/categories/:id', component: CategoryEdit },
        { path: '*', component: NotFound },
    ]
})
